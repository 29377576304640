import { Facility } from "@app/shared";
import { FacilityEventsTable } from "../../shared";


type FacilityEventsProps = {
    facility: Facility;
    onChange: () => any;
};
export const FacilityEvents: React.FC<FacilityEventsProps> = ({ facility, onChange }) => {
    return (
        <FacilityEventsTable facility={facility} />
    );
}