export enum TurnType {
	Straight = 0,
	Left = 1,
	Right = 2,
	UTurn = 3,
}

export const TurnTypeDisplayNames: { [key in keyof typeof TurnType]?: string} = {
	"Straight": "Straight",
	0 : "Straight",
	"Left": "Left",
	1 : "Left",
	"Right": "Right",
	2 : "Right",
	"UTurn": "UTurn",
	3 : "UTurn",
}

export const getTurnTypeDisplayName = (key: TurnType | (keyof typeof TurnType & string)) => {
	return TurnTypeDisplayNames[key];
}

