import { DetectionZone, IDetectionZone } from "./DetectionZone.gen";
import { ScreenLine, IScreenLine } from "./ScreenLine.gen";
import { Device, IDevice } from "./Device.gen";
import { LaneLine, ILaneLine } from "./LaneLine.gen";
import { Spot, ISpot } from "./Shared.Geometry.Spot.gen";

export interface IFacilityLayoutConfiguration {
	detectionZones: IDetectionZone[] | undefined;

	screenLines: IScreenLine[] | undefined;

	devices: IDevice[] | undefined;

	laneLines: ILaneLine[] | undefined;

	referencePoint: ISpot | undefined;

	$type?: string | undefined;
}
export class FacilityLayoutConfiguration {
	constructor(data?: IFacilityLayoutConfiguration) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.detectionZones = data.detectionZones ? data.detectionZones.map((d: any) => new DetectionZone(d)) : [];
		this.screenLines = data.screenLines ? data.screenLines.map((s: any) => new ScreenLine(s)) : [];
		this.devices = data.devices ? data.devices.map((d: any) => new Device(d)) : [];
		this.laneLines = data.laneLines ? data.laneLines.map((l: any) => new LaneLine(l)) : [];
		if (data.referencePoint) { this.referencePoint = new Spot(data.referencePoint); }
	}

	detectionZones: DetectionZone[] | undefined = [];

	screenLines: ScreenLine[] | undefined = [];

	devices: Device[] | undefined = [];

	laneLines: LaneLine[] | undefined = [];

	referencePoint: Spot | undefined;

	static $type: string = "CoreApi.Models.View.FacilityLayoutConfiguration, ";
	$type?: string | undefined = FacilityLayoutConfiguration.$type;
}

