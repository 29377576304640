import { Facility, LaneLine, ScreenLine, Target, Vector2 } from "@app/shared";
import { useEffect, useState } from "react";
import { LaneLineMapShape } from "./LaneLineMapShape";


export type TargetMapShapeProps = { facility: Facility, target?: Target, isSelected?: boolean, isEditable?: boolean, onUpdate?: (shape: ScreenLine) => void };
export const TargetMapShape: React.FC<TargetMapShapeProps> = ({ facility, target, isEditable, onUpdate, isSelected }) => {

    const [line, setLine] = useState<LaneLine>();

    useEffect(() => {
        if (target?.history && target.history.length >= 2) {
            const sl = new LaneLine();
            sl.points = target.history.map(h => new Vector2({ x: h.position?.x ?? 0, y: h.position?.y ?? 0 })); //These should already be in the correct order oldest to newest
            sl.id = target?.id;
            setLine(sl);
        } else if (target?.endPosition && target.startPosition) {
            const sl = new LaneLine();
            sl.id = target?.id;
            sl.points = [new Vector2({ x: target?.startPosition?.x, y: target?.startPosition.y }), new Vector2({ x: target?.endPosition?.x, y: target?.endPosition.y })];
            setLine(sl);
        }
    }, [target, target?.endPosition])
    return (<LaneLineMapShape shape={line} facility={facility} />);
}