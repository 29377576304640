export enum FacilityEventType {
	ScreenLineChange = 0,
	DetectionZoneChange = 1,
	WrongWay = 2,
	Speeding = 3,
	CountAdjustment = 4,
	Target = 5,
	DetectionZonePresence = 6,
	DetectionZoneDwell = 7,
}

export const FacilityEventTypeDisplayNames: { [key in keyof typeof FacilityEventType]?: string} = {
	"ScreenLineChange": "Screen Line Change",
	0 : "Screen Line Change",
	"DetectionZoneChange": "Detection Zone Change",
	1 : "Detection Zone Change",
	"WrongWay": "Wrong Way",
	2 : "Wrong Way",
	"Speeding": "Speeding",
	3 : "Speeding",
	"CountAdjustment": "Count Adjustment",
	4 : "Count Adjustment",
	"Target": "Target Detected",
	5 : "Target Detected",
	"DetectionZonePresence": "Detection Zone Presence",
	6 : "Detection Zone Presence",
	"DetectionZoneDwell": "Detection Zone Dwell",
	7 : "Detection Zone Dwell",
}

export const getFacilityEventTypeDisplayName = (key: FacilityEventType | (keyof typeof FacilityEventType & string)) => {
	return FacilityEventTypeDisplayNames[key];
}

