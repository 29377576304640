import { DetectionZone, Device,  LaneLine,  Ruler, ScreenLine, Spot, Vector2 } from "@app/shared";
import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { getPathForFieldOfView, getRotation } from "../../../helpers";
export type Shape = Device | ScreenLine | DetectionZone | Spot | Ruler | LaneLine;

type SvgShapeProps = {
    color?: string;
    opacity?: number;
    shape: Shape;
    currentPosition?: Vector2;
    scaleRatio: number;
    additionalAttributes?: Map<string, object>;
    className?: string;
    onVertexDrag?: undefined | ((e: MouseEvent | React.MouseEvent, shape: Shape, pointIndex: number) => any);
} & React.SVGProps<SVGPathElement | SVGCircleElement | SVGPolygonElement | SVGPolylineElement>;

export const SvgShape: React.FC<SvgShapeProps> = ({ color, opacity, shape, currentPosition, scaleRatio: scaleRotation, additionalAttributes, className: classNameIn, onVertexDrag, ...props }) => {
    const className = cn(classNameIn, shape.$type);
    const [isDragging, setIsDragging] = useState(false);

    const getShapePoints = (points: Vector2[], additionalPoints?: Vector2[]) => {
        var allPoints = points.concat(additionalPoints ?? []);
        var coordinates = allPoints.map(point => `${point.x},${point.y}`);
        return coordinates.join(" ");
    };

    const handleMouseMove = useCallback((e: MouseEvent) => {
        if (!onVertexDrag || !isDragging) return;
        onVertexDrag(e, shape, 0);
    }, [isDragging, onVertexDrag, shape]);

    const handleVertexMouseDown = (e: React.MouseEvent) => {
        if (!onVertexDrag) return;
        onVertexDrag(e, shape, 0);
        setIsDragging(true);
    };

    const handleVertexMouseUp = (e: React.MouseEvent) => {
        if (!onVertexDrag) return;
        onVertexDrag(e, shape, 0);
        setIsDragging(false);
    };

    useEffect(() => {
        document.removeEventListener("mousemove", handleMouseMove);
        if (isDragging)
            document.addEventListener("mousemove", handleMouseMove);
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
        }
    }, [isDragging, handleMouseMove]);

    const renderShape = (currentPosition?: Vector2) => {
        if (shape.$type === Device.$type) {
            const fov = shape as any as Device;
            var style = fov.color != null ? { fill: fov.color, stroke: fov.color } : {};
            const isFullyConfigured = fov.desiredTiltDegrees && fov.height;
            return (
                <>
                    <text x={fov.positionX} y={fov.positionY - 20} textAnchor="middle" stroke="black" fill="black" fontSize={15}>{fov.name}</text>
                    {isFullyConfigured &&
                        <path
                            className="field-of-view"
                            d={getPathForFieldOfView(fov.positionX, fov.positionY, fov.height ?? 0, fov.desiredTiltDegrees ?? 0, 90, 30, scaleRotation)}
                            transform={getRotation(fov.positionX, fov.positionY, fov.headingDegrees ?? 0)}
                            style={{ ...style, fillOpacity: .15 }}
                            {...props as React.SVGProps<SVGPathElement>}
                        />
                    }

                    <circle

                        className={cn(className, onVertexDrag && "cursor-pointer")}
                        cx={fov.positionX}
                        cy={fov.positionY}
                        r={6}
                        onMouseDown={handleVertexMouseDown}
                        onMouseUp={handleVertexMouseUp}
                        style={style}
                        {...props as React.SVGProps<SVGCircleElement>}
                    />

                </>
            );
        } else {
            const otherShape = shape as Exclude<Shape, Device>;
            const startingPoint = otherShape?.points ? otherShape.points[0] : undefined;
            const isDrawing = currentPosition !== undefined;
            const willClose = isDrawing && startingPoint === currentPosition;

            var svgStyle = { fillOpacity: opacity ?? .3, strokeWidth: 2, stroke: color ?? "green" };

            if (otherShape.$type === ScreenLine.$type) {
                svgStyle.stroke = color ?? "red"
                svgStyle.strokeWidth = 4;
            }

            const renderLineOrPolygon = () => {
                if (isDrawing && !willClose) {
                    return (
                        <polyline
                            className={className}
                            style={{ ...svgStyle, vectorEffect: "non-scaling-stroke" }}
                            points={getShapePoints(otherShape.points ?? [], currentPosition ? [currentPosition] : [])}
                            {...props as React.SVGProps<SVGPolylineElement>}
                        />
                    );
                } else {
                    return (
                        <polygon
                            className={className}
                            style={{ ...svgStyle, fill: color ?? "green" }}
                            points={getShapePoints(otherShape.points ?? [])}
                            {...props as React.SVGProps<SVGPolygonElement>}
                        />
                    );
                }
            }
            return (
                <>
                    {renderLineOrPolygon()}
                    {startingPoint && (isDrawing || (otherShape.points?.length === 1)) &&
                        <circle
                            className="polygon-starting-point"
                            style={{ ...svgStyle, fill: color ?? "red", stroke: color ?? "red" }}
                            cx={startingPoint.x}
                            r={3}
                            cy={startingPoint.y}
                        />
                    }
                </>
            );
        }
    }

    return renderShape(currentPosition);
}
