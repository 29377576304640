import { LaneLine, TurnType } from "@app/shared";
import { EnumSelect, Input, InputLabel, InputRow, NumericInput } from "../../inputs";
import { ColorPicker } from "../ColorPicker";

type LaneLineConfigProps = {
    laneLine: LaneLine;
    onChange: (zone: LaneLine) => void;
    isEditable?: boolean;
    isCollapsed: boolean;
};

export const LaneLineConfig: React.FC<LaneLineConfigProps> = ({ laneLine, onChange, isCollapsed, isEditable = false }) => {
    const handleOnChange = (key: keyof LaneLine, value: LaneLine[keyof LaneLine]) => {
        onChange(new LaneLine({ ...laneLine, [key]: value }));
    }

    const handleChangeLaneNumber = (laneNumber: number | undefined) => {
        if (laneNumber === undefined || laneNumber == null) {
            onChange(new LaneLine({ ...laneLine, "laneNumber": laneNumber }));
            return
        }
        if (laneNumber > 0) {
            onChange(new LaneLine({ ...laneLine, "laneNumber": laneNumber }));
            return
        }

    }
    if (isCollapsed) { return null; }
    return (
        <>
            <InputRow>
                <InputLabel>Color</InputLabel>
                <div className="flex place-items-center">
                    <ColorPicker value={laneLine.color ?? ""} onChange={(newColor) => handleOnChange("color", newColor)} disabled={!isEditable} />
                    <div className="flex-grow ml-5">
                        <Input value={laneLine.color ?? ""} onChange={(e) => handleOnChange("color", e.target.value)} disabled={!isEditable} />
                    </div>
                </div>
            </InputRow>
            <InputRow>
                <Input onChange={(e) => handleOnChange("name", e.target.value)} label="Lane Line Name" value={laneLine.name} disabled={!isEditable} />
            </InputRow>
            <InputRow>
                <EnumSelect enumType={TurnType} label="Turn Type" emptyOptionText="Select Turn Type" onChange={(e) => handleOnChange("turnType", e)} value={laneLine.turnType} disabled={!isEditable} />
            </InputRow>
            <InputRow >
                <NumericInput label="Lane Number" onChange={(e) => handleChangeLaneNumber(e)} value={laneLine.laneNumber} disabled={!isEditable} debounce={0} />
            </InputRow>
        </>
    );
}
