import { FunctionComponent, useEffect, useState } from "react";
import { getNumberFormatSettings } from "react-native-localize";
import { Input, InputProps } from ".";
import { useDebounce } from "react-use";


type NumericInputProps = {
    value?: number | string;
    onChange?: (value?: number) => void;
    required?: boolean;
    allowFloat?: boolean;
    debounce?: number
} & Omit<InputProps, "value" | "onChange">;

function parseValue(value: string, defaultValue: number | undefined, parseAsFloat: boolean): number | undefined {
    const { decimalSeparator } = getNumberFormatSettings();
    const americanizedValue = value.replace(decimalSeparator, '.');
    if (parseAsFloat)
        return Number.parseFloat(americanizedValue) ?? defaultValue;
    return Number.parseInt(americanizedValue) ?? defaultValue;
}

export const NumericInput: FunctionComponent<NumericInputProps> = (props) => {
    const { value, onChange, required = false, allowFloat = true, ...rest } = props;
    const [displayValue, setDisplayValue] = useState<string>("");    
    //TODO: Maybe we should add scientific notation if it's over 5 decimals
    const valueAsString = () => (value?.toString());

    useEffect(() => {
        setDisplayValue(valueAsString() ?? "");
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const onValueChange = (newValue: string) => {
        setDisplayValue(newValue);        
    }

    useDebounce(
        () => {
            if(!required && (displayValue === "" || displayValue === undefined)){
                onChange?.();
                return;
            }
            const parsedVal = parseValue(displayValue, required ? 0 : undefined, allowFloat);
            if(required){
                if (parsedVal !== null && parsedVal !== undefined && !isNaN(parsedVal) && parsedVal !== value) {
                    onChange?.(parsedVal);
                }
                return
            }

            if(parsedVal !== undefined &&!isNaN(parsedVal) && parsedVal !== value){
                onChange?.(parsedVal);
                return
            }
 
        },
        props.debounce ?? 300,
        [displayValue] 
    );

    return (
        <Input
            value={displayValue}
            onBlur={() => setDisplayValue(valueAsString() ?? "")}
            onChange={(e) => (onValueChange(e.target.value))}
            {...rest} />
    );
}