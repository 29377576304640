import { Vector2, IVector2 } from "./System.Numerics.Vector2.gen";
import { Int32, IInt32 } from "./System.Int32.gen";
import { TurnType } from "./Shared.TurnType.gen";

export interface ILaneLine {
	points: IVector2[] | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	name: string | undefined;

	color: string | undefined;

	laneNumber: number | undefined;

	turnType: TurnType | undefined;

	$type?: string | undefined;
}
export class LaneLine {
	constructor(data?: ILaneLine) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.points = data.points ? data.points.map((p: any) => new Vector2(p)) : [];
	}

	points: Vector2[] | undefined = [];

	id: string | undefined;

	facilityId: string | undefined;

	name: string | undefined;

	color: string | undefined;

	laneNumber: number | undefined;

	turnType: TurnType | undefined;

	static $type: string = "CoreApi.Models.LaneLine, ";
	$type?: string | undefined = LaneLine.$type;
}

