import { FacilityGroup, FacilityGroups, useAuth } from "@app/shared";
import { FC, MouseEventHandler, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pages } from ".";
import { toastError, toastSuccess } from "../../helpers";
import { useFetch } from "../../hooks";
import { useEditModelDialog } from "../../hooks/useEditModelDialog";
import { DashboardLayout } from "../layouts";
import { IconLink, Icons, Input, InputRow, SortableTable } from "../shared";
import { DropDownMenu } from "../shared/DropDownMenu";
import { OrganizationDropdown } from "../shared/OrganizationDropdown";

export const ViewFacilityGroups: FC = (props) => {
    const { user, currentOrganizationId, setCurrentOrganizationId } = useAuth();
    const [facilityGroups, , fetchHelpers] = useFetch(() => FacilityGroups.getByOrganizationId(currentOrganizationId), [currentOrganizationId]);
    const navigate = useNavigate();

    const newGroupDialog = useEditModelDialog(FacilityGroup)

    const handleAddOnClick: MouseEventHandler = (e) => {
        newGroupDialog.show(async (newGroup) => {
            await FacilityGroups.save(newGroup as FacilityGroup);
            toastSuccess("Created", "A new location group was created!");
            fetchHelpers.refreshData();
        }, "Add Location Group", { organizationId: currentOrganizationId });
    };

    const handleEditOnClick = (facilityGroup: FacilityGroup) => {
        newGroupDialog.show(async (changes) => {
            await FacilityGroups.save(changes as FacilityGroup)
            toastSuccess("Saved", "location group has been saved!");
            fetchHelpers.refreshData();
        }, "Edit Location Group", facilityGroup)
    };

    const handleDeleteOnClick = async (facilityGroup: FacilityGroup) => {
        try {
            await FacilityGroups.deleteGroup(facilityGroup.id);
            toastSuccess("Deleted", "Location group has been removed.");
            fetchHelpers.refreshData();
        } catch (e) {
            toastError("Unable to delete", e + "");
        }

    };

    const changeOrgs = async (newOrgId: string) => {
        setCurrentOrganizationId(newOrgId);
    };

    useEffect(() => {
        if (!user)
            return;
        if (!currentOrganizationId) {
            navigate(Pages.AddOrganization.getUrl());
        }
    }, [user, navigate, currentOrganizationId]);


    return (
        <DashboardLayout
            leftChildren={<OrganizationDropdown onChange={changeOrgs} initOrgId={currentOrganizationId} />}
            centerChildren="Location Groups">
            <>
                <SortableTable
                    isLoading={fetchHelpers.isLoading}
                    emptyValue={
                        <div className="py-5">
                            <p className="py-3">
                                No location groups were found.
                            </p>
                            <IconLink Icon={Icons.PlusCircle} iconSize={30} onClick={handleAddOnClick}>Add New Group</IconLink>
                        </div>
                    }
                    onRowClick={(rowData) => {
                        navigate(Pages.ViewFacilityGroup.getUrl({ id: rowData.id ?? "" }));
                    }}
                    columns={[
                        {
                            header: "Name",
                            dataKey: "name",
                            width: 250,
                        },
                        {
                            header: "Locations",
                            dataKey: "facilityCount",
                            width: 150
                        },
                        {
                            header:
                                <IconLink iconSize={20} Icon={Icons.PlusCircle} onClick={handleAddOnClick} />
                            ,
                            width: 10,
                            renderFunc: fg => (<DropDownMenu>
                                <IconLink Icon={Icons.Edit} onClick={() => handleEditOnClick(fg!)} >Edit</IconLink>
                                <IconLink Icon={Icons.Trash} onClick={() => handleDeleteOnClick(fg!)} >Delete</IconLink>
                            </DropDownMenu>),
                        }
                    ]}
                    data={facilityGroups}
                />
                {newGroupDialog.renderDialog((model, helpers) => (
                    <div>
                        <InputRow>
                            <Input label="Group Name" {...helpers.bindingsFor("name")} />
                        </InputRow>
                    </div>
                ))}
            </>
        </DashboardLayout>
    );
}