import { AggregatedTargetAttribute, AggregationPeriod, CustomClaimValue, Facility, Reports, TrafficReportConfiguration } from "@app/shared";
import { addDays } from "date-fns";
import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks";
import { AllTargetTypes } from "../../reports/reportHelpers";
import { Icons, LoadingIndicator, PageContainer, Select } from "../../shared";
import { AuthorizeView } from "../../shared/AuthorizeView";
import { RecentTargetsChart } from "../../shared/charts";
import { HoverTooltip } from "../../shared/HoverTooltip";

type FacilityTargetsDashboardProps = {
    facility: Facility;
};
export const FacilityTargetsDashboard: React.FC<FacilityTargetsDashboardProps> = ({ facility }) => {
    const [chartDeviceId, setChartDeviceId] = useState<string | undefined>(!!(facility.devices?.length) ? facility.devices![0].id! : undefined);
    const [recentTargets, errors, recentTargetHelpers] = useFetch(() => chartDeviceId ? Reports.getTotalVolume(AggregatedTargetAttribute.TargetType, AggregationPeriod.Day, { deviceIds: [chartDeviceId], inclusiveStart: addDays(new Date(), -14), exclusiveEnd: new Date(), groupingValues: AllTargetTypes } as TrafficReportConfiguration) : Promise.resolve(undefined), [chartDeviceId]);//TODO: TT hard code here

    //When our facility changes, load the recent targets for the first device
    useEffect(() => {
        if (!chartDeviceId && (facility.devices?.length ?? 0) > 0) {
            setChartDeviceId(facility.devices![0].id);
        }
    }, [facility, chartDeviceId]);

    return (

        <PageContainer title="Recent Targets" titleRight={
            <Select options={facility.devices?.map(d => ({ label: d.name, value: d.id }))} value={chartDeviceId} onChange={e => setChartDeviceId(e.target.value)} />
        }>
            {recentTargetHelpers.isLoading && <LoadingIndicator isLoading={recentTargetHelpers.isLoading} />
            }
            {(recentTargets && !errors && !recentTargetHelpers.isLoading && recentTargets.length > 0) &&
                <div className="w-full max-h-96 ">
                    <RecentTargetsChart data={recentTargets} facility={facility} device={facility.devices?.find(x => x.id === chartDeviceId)!} height={300} margin={{ top: 10, right: 60, bottom: 50, left: 60 }} />
                </div>
            }
            {(recentTargets && !errors && !recentTargetHelpers.isLoading && recentTargets.length === 0) &&
                <div className="flex justify-center ">
                    <p className="text-1xl brand-primary-lightened">No recent targets</p>
                </div>
            }

            {errors && !recentTargetHelpers.isLoading &&
                <HoverTooltip placement="auto" hoverable={
                    <p className="text-error-icon flex gap-3 place-items-center">
                        <Icons.Warning /> There was an error loading recent targets for this device.
                    </p>
                }>
                    <AuthorizeView claim={CustomClaimValue.SuperAdmin}><span>{errors}</span></AuthorizeView>
                </HoverTooltip>
            }
        </PageContainer>

    );
}