import { FacilityGroups, OptionValue } from "@app/shared";
import { useEffect, useState } from "react";
import { useFetch } from "../../hooks";
import { DropDownMenu } from "./DropDownMenu";
import { Icons } from "./Icons";
import { Link } from "./Link";

type FacilityGroupDropdownProps = {
    currentGroupId: string;
    onSelected: (newGroupId: string) => void;
};
export const FacilityGroupDropdown: React.FC<FacilityGroupDropdownProps> = (props) => {
    //const { currentOrganizationId } = useAuth(); 
    const [allGroups] = useFetch(() => FacilityGroups.getAllFacilityGroupOptions());
    const [selectedGroup, setSelectedGroup] = useState<OptionValue>();

    useEffect(() => {
        setSelectedGroup(allGroups?.find(o => o.value === props.currentGroupId));
    }, [allGroups, props.currentGroupId]);

    return (
        <>
            {(allGroups && allGroups.length > 1) &&
                <DropDownMenu toggleChild={<div className="flex items-center space-x-2"><div>{selectedGroup?.label ?? ""}</div><Icons.CaretDown /></div>}>
                    {allGroups?.map(g => (
                        <Link key={g.value} onClick={() => props.onSelected(g.value!)}>{g.label}</Link>
                    ))}
                </DropDownMenu>
            }
        </>
    );
}
