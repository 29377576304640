import { LaneLine, Vector2 } from "../models";

declare module "../models/LaneLine.gen" {
    interface LaneLine {
        clampPointIfClosing(point: Vector2, threshold: number): Vector2;
        isComplete(): boolean;
    }
}

LaneLine.prototype.isComplete = function () {
    const numPoints = this.points?.length ?? 0;
    return (numPoints > 2 && isValidGuid(this.id));
}

LaneLine.prototype.clampPointIfClosing = function (point: Vector2, threshold: number) {
    return point; 
}

function isValidGuid(guid: string | undefined): boolean {
    const emptyGuid = "00000000-0000-0000-0000-000000000000";
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    return !!guid && guidRegex.test(guid) && guid !== emptyGuid;
}