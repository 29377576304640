import { Guid, IGuid } from "./System.Guid.gen";
import { FacilityEventType } from "./Shared.FacilityEventType.gen";

export interface IFacilityEvent {
	id: string | undefined;

	facilityId: string | undefined;

	deviceId: string | undefined;

	externalTargetId: string | undefined;

	detectionZoneId: string | undefined;

	screenLineId: string | undefined;

	dateCreated: string | undefined;

	eventType: FacilityEventType;

	eventValue: number;

	attachmentUrl: string | undefined;

	$type?: string | undefined;
}
export class FacilityEvent {
	constructor(data?: IFacilityEvent) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.dateCreated) { this.dateCreated = new Date(data.dateCreated); }
	}

	id: string | undefined;

	facilityId: string | undefined;

	deviceId: string | undefined;

	externalTargetId: string | undefined;

	detectionZoneId: string | undefined;

	screenLineId: string | undefined;

	dateCreated: Date | undefined = new Date();

	eventType: FacilityEventType = 0;

	eventValue: number = 0;

	attachmentUrl: string | undefined;

	static $type: string = "CoreApi.Models.FacilityEvent, ";
	$type?: string | undefined = FacilityEvent.$type;
}

