/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post } from "./api_client";
import { Target, ITarget, LastTargetEntity, ILastTargetEntity } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getTargetWithHistory(targetId?: string, deviceId?: string): ApiResult<Target> {
	return get<ITarget>(`/api/target/${ targetId }/history/with-device/${ deviceId }`).then(result => result === undefined ? undefined :  new Target(result));
}

export async function getTargetWithHistoryByExternalId(externalId?: string, deviceId?: string): ApiResult<Target> {
	return get<ITarget>(`/api/target/history-by-external-id/${ externalId }/with-device/${ deviceId }`).then(result => result === undefined ? undefined :  new Target(result));
}

export async function getLastTargetWithPhotoForDevice(deviceId?: string): ApiResult<Target> {
	return get<ITarget>(`/api/target/last-target-for-device/${ deviceId }`).then(result => result === undefined ? undefined :  new Target(result));
}

export async function getLastTargetsForDevices(deviceIds?: string[]): ApiResult<LastTargetEntity[]> {
	return post<ILastTargetEntity[]>(`/api/target/last-targets-for-devices`, deviceIds).then(result => result === undefined ? undefined : result.map(o => new LastTargetEntity(o)));
}
